<template>
  <div class="text-left">
    <!-- <BaseHeader :title="'Editors'"></BaseHeader> -->

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>


      <div   class="card d-flex align-items-center flex-row w-100 justify-content-between  p-3  text-left my-1 " style="">
        <h2 class="card-title w-50 font-weight-black text-capitalize">
          Editors
        </h2>
        
        <v-text-field
            v-model="searchValue"
            dense
            outlined
            label="Search Editor "
            name="url"
            type="text"
            hide-details
          />

      </div>

      <div class=" my-2  order-table-custom ">
      
      <div class=" order-listing-header  row my-2  p-3 mx-sm-0 mx-1 ">  
        <div class="col-1 text-left  "> ID </div>
        <div class="col-2 text-left ">Name</div>
        <div class="col-2 text-left">Email</div>
        <div class="col-2 text-left"> Phone</div>
        <div class="col-1 text-left">Country</div>
        <div class="col-1 text-left">Status</div>
        <div class="col-1 text-left"> Rating</div>
        <div class="col-2 text-left">Action</div>
        <!-- <div class="col-2 text-left">Action </div> -->
     </div>

     <!-- {{editors}} -->
   
     <div   v-if="filteredEditors.length >  0" class="">
     <div v-for="(editor, index) in filteredEditors" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
        <div class="col-1 d-flex align-items-start justify-content-start text-left">
          <router-link class="text-left"
            :to="{ name: 'WriterProfile', params: { id: editor.id } }"
            >{{ editor.id || "" }}</router-link>
           
        </div>
        <div class="col-2 text-left">
          <router-link
            :to="{ name: 'WriterProfile', params: { id: editor.id } }"
            >{{ editor.names || "" }}</router-link
          >
          <!-- <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ order.subject_area_text || "" }}</div> -->
        </div>
        <div class="col-2 text-left">{{ editor.email || "" }}</div>
        <div class="col-2 text-left"
        
        >  {{ editor.phone || "" }} </div>
        <div class="col-1 text-left"
        
        >  {{ editor.country || "" }} </div>
        <div class="col-1 text-left">
          <template >
          <v-chip v-if="editor.status == '1'" color="primary" outlined small
              >Active</v-chip
            >
          <v-chip v-else color="error" x-small>Inactive</v-chip>  
          </template>
        </div>
        <div class="col-1 text-left">{{ editor.rating || "" }}</div>

        <div class="col-2 text-left  ">
          <div class="btn-group" v-if="editor.id">
          <router-link
            class=""
            :to="{ name: 'WriterProfile', params: { id: editor.id } }"
            >           
            <v-btn
              
              rounded
              color="primary"
            >
              <v-icon left>
                mdi-eye
              </v-icon>
              View
            </v-btn>
            
            </router-link
          >
          
        </div>
        </div>
     </div>
    </div>
    <div class="text-center w-100  py-2"  v-if="filteredEditors.length < 1">
        
          <strong class="custom_imageEmpty  text-center" >No editors.</strong>
        
    </div>

    <!-- {{filteredEditors.length}} -->

    </div>

     
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Editors",
  computed: {
    ...mapState("editors", ["loading", "editors", "headers"]),
    filteredEditors() {
        let tempRecipes = this.editors
        
        // Process search input
        if (this.searchValue != '' && this.searchValue) {
            tempRecipes = tempRecipes.filter((item) => {
            return item.email
                .toUpperCase()
                .includes(this.searchValue.toUpperCase())
            })
           
        }
       
        
        // Filter out by cooking time
        // if (this.maxCookingTime)
        // tempRecipes = tempRecipes.filter((item) => {
        //     return (item.cookingTime <= this.maxCookingTime)
        // })
            
        // Sort by alphabetical order
            tempRecipes = tempRecipes.sort((a, b) => {
                if (this.sortBy == '0') {
                    let fa = a.status.toLowerCase(), fb = b.status.toLowerCase()
            
                if (fa < fb) {
                    return -1
                }
                if (fa > fb) {
                    return 1 
                }
                return 0
                
                // Sort by cooking time
                } else if (this.sortBy == '1') {
                return a.cookingTime - b.cookingTime
            }
            })
            
            // Show sorted array in descending or ascending order
            if (!this.ascending) {
                tempRecipes.reverse()
            }
            
            return tempRecipes
    },
  },
  data() {
    return {
      countries: [],
      searchValue:'',

    };
  },

  methods: {
    ...mapActions("editors", ["getEditors"]),
  },
  async mounted() {
    await this.getEditors();
  },
};
</script>